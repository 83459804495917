import { graphql } from "gatsby"
import React from "react"
import {
  CompanySection,
  DistributionSection,
  GetInTouchSection,
  OurServiceSection,
  ProductionSection
} from "../components"
import Container from "../components/container"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"

const IndexPage = ({ data: { home }, intl }) => {
  return (
    <Container brandImage={home.heroImage} intl={intl}>
      <SEO title="Home Page" />

      <CompanySection theCompany={home.theCompany} />
      <OurServiceSection services={home.services} />
      <ProductionSection />
      <DistributionSection />
      <GetInTouchSection />
    </Container>
  )
}

export const query = graphql`
  query HomePageQuery($locale: String) {
    home: contentfulHomePage(node_locale: { eq: $locale }) {
      theCompany {
        theCompany
      }
      services {
        id
        title
        description {
          description
        }
        image {
          file {
            url
            fileName
            contentType
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      heroImage {
        title
        description
        sizes(quality: 100) {
          ...GatsbyContentfulSizes_withWebp
        }
      }
    }
  }
`

export default injectIntl(IndexPage)
